<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mt-4 ml-4">
      <v-card-title>添加连续测试量表</v-card-title>
      <v-row class="mx-4">
        <v-col cols="10">
          <v-autocomplete
            filled
            dense
            clearable
            chips
            small-chips
            deletable-chips
            multiple
            :items="pickableLbCatItems"
            item-text="displayName"
            item-value="guid"
            v-model="selectedLbGuidList"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.displayName }}
                  <v-icon
                    v-if="!!item.timeBoxSecond"
                    class="ml-3"
                    color="grey"
                    small
                  >
                    mdi-timer-outline
                  </v-icon>
                  <v-icon
                    v-if="!!item.isQuesShuffle"
                    class="ml-3"
                    color="grey"
                    small
                  >
                    mdi-shuffle-variant
                  </v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            depressed
            :disabled="!selectedLbGuidList || !selectedLbGuidList.length"
            @click="addLbListToGroup(selectedLbGuidList)"
          >
            添加量表
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="ml-4 mb-10">
      <v-card-title>连续测试量表</v-card-title>
      <v-row class="mx-2 mb-6">
        <v-col cols="12">
          <v-list>
            <draggable
              v-model="pickedLbItems"
              handle=".drag-handle"
              animation="200"
              @start="isDragging = true"
              @end="isDragging = false"
              @change="groupLbOrderChanged"
            >
              <transition-group
                type="transition"
                :name="!isDragging ? 'flip-list' : null"
              >
                <v-list-item v-for="item in pickedLbItems" :key="item.guid">
                  <v-sheet
                    class="my-2 px-4 d-flex justify-space-between"
                    width="100%"
                    elevation="2"
                    tile
                  >
                    <v-icon class="drag-handle mr-4">
                      mdi-reorder-horizontal
                    </v-icon>
                    <v-list-item-title>
                      {{ item.displayName }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!!item.timeBoxSecond"
                            class="ml-3"
                            color="grey"
                            v-on="on"
                            v-bind="attrs"
                          >
                            mdi-timer-outline
                          </v-icon>
                        </template>
                        此量表设置了答题时间限制
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-if="!!item.isQuesShuffle"
                            class="ml-3"
                            color="grey"
                            v-on="on"
                            v-bind="attrs"
                          >
                            mdi-shuffle-variant
                          </v-icon>
                        </template>
                        此量表使用了“乱序答题”模式
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-action>
                      <v-btn
                        icon
                        color="red"
                        @click="removeOneLbFromGroup(item.guid)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-sheet>
                </v-list-item>
              </transition-group>
            </draggable>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import draggable from "vuedraggable";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  fetchPickableLbItems,
  fetchPickedLbItems,
  addLbsToGroup,
  removeLbFromGroup,
  updateGroupLbOrder
} from "@/api/lb.js";

export default {
  components: {
    draggable,
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pickableLbCatItems: [],
      pickedLbItems: [],
      selectedLbGuidList: [],
      isDragging: false,
      isLoading: false,
      errorMsg: ""
    };
  },

  watch: {
    guid(newGuid) {
      this.selectedLbGuidList = [];
      return this.refreshLbItems(newGuid);
    }
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid"
    })
  },

  methods: {
    async refreshLbItems(groupGuid) {
      try {
        this.isLoading = true;
        let lbItems = await fetchPickableLbItems(this.userGuid, groupGuid);
        // 排序，常用量表需要在最上面
        let groupedLbItems = _.chain(lbItems)
          .sortBy(lb => (lb.isTopMost ? 0 : 1))
          .groupBy("categoryName")
          .value();
        let lbCatItems = [];
        for (let cat in groupedLbItems) {
          lbCatItems.push({ divider: true });
          lbCatItems.push({ header: cat === "TOPMOST" ? "常用量表" : cat });
          lbCatItems.push(...groupedLbItems[cat]);
        }
        this.pickableLbCatItems = lbCatItems;
        let pickedLbs = await fetchPickedLbItems(groupGuid);
        // 排序
        this.pickedLbItems = pickedLbs.sort(
          (lb1, lb2) => lb1.order - lb2.order
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async addLbListToGroup(lbGuidList) {
      try {
        this.isLoading = true;
        await addLbsToGroup(this.guid, lbGuidList);
        // 重置控件
        this.selectedLbGuidList = [];
        await this.refreshLbItems(this.guid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async removeOneLbFromGroup(lbGuid) {
      try {
        this.isLoading = true;
        await removeLbFromGroup(this.guid, lbGuid);
        // 重置控件
        await this.refreshLbItems(this.guid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async groupLbOrderChanged() {
      try {
        this.isLoading = true;
        let changedLbGuidList = this.pickedLbItems.map(item => item.guid);
        await updateGroupLbOrder(this.guid, changedLbGuidList);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.refreshLbItems(this.guid);
  }
};
</script>

<style lang="scss" scoped>
.drag-handle {
  cursor: move;
}
</style>
