<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-4 my-4">
      <v-card-title>测量统计信息</v-card-title>
      <v-card-text class="px-6 stat-chart-div">
        <div class="d-flex align-center justify-space-between mb-4">
          <v-btn-toggle
            v-model="currentChartMode"
            mandatory
            dense
            color="primary"
          >
            <v-btn>测量日期</v-btn>
            <v-btn>量表</v-btn>
          </v-btn-toggle>
          <div class="d-flex align-center">
            <AppDateRangePicker
              outlined
              label="测量日期"
              v-model="pickedDateRange"
            />
            <AppMenuBtn
              icon="mdi-arrow-down-drop-circle-outline"
              tooltip="快速选择日期"
              color="primary"
            >
              <v-list>
                <v-list-item
                  v-for="(item, idx) in presetDateRangeItems"
                  :key="idx"
                  @click="clickPresetDateRangeItem(item.value)"
                >
                  {{ item.text }}
                </v-list-item>
              </v-list>
            </AppMenuBtn>
            <AppTooltipBtn
              class="ml-6 my-4"
              color="primary"
              icon="mdi-refresh"
              tooltip="刷新图表数据"
              @click="reloadGroupTestData(guid)"
            />
          </div>
        </div>
        <TestCaseCountBar
          :title="name"
          :chart-mode="currentChartMode"
          :case-stat-list="groupTestCaseStatList"
        />
      </v-card-text>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import AppMenuBtn from "@/components/AppMenuBtn";
import AppDateRangePicker from "@/components/AppDateRangePicker";
import TestCaseCountBar from "@/components/statistics/TestCaseCountBar";
import { groupTestCases } from "@/api/statistic";
import {
  presetDateRangeSelects,
  getStartEndDateFromPresetRange,
  getStartDateFromDateRange,
  getEndDateFromDateRange
} from "@/utils/dateTime";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppTooltipBtn,
    AppMenuBtn,
    AppDateRangePicker,
    TestCaseCountBar
  },

  props: {
    guid: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      groupTestCaseStatList: [],
      presetDateRangeItems: presetDateRangeSelects,
      pickedDateRange: [],
      currentChartMode: 0,
      errorMsg: ""
    };
  },

  watch: {
    guid(newGuid) {
      this.reloadGroupTestData(newGuid);
    },
    pickedDateRange(newDateRange) {
      // 只有 dateRange 只选择了一个日期的情况下不更新
      if (!newDateRange || newDateRange.length != 1) {
        this.reloadGroupTestData(this.guid);
      }
    }
  },

  computed: {
    defaultPresetDateRange() {
      return this.presetDateRangeItems.find(item => item.default);
    }
  },

  methods: {
    clickPresetDateRangeItem(itemValue) {
      let startEndDate = getStartEndDateFromPresetRange(itemValue);
      this.pickedDateRange = [startEndDate.startDate, startEndDate.endDate];
    },
    async reloadGroupTestData(groupGuid) {
      try {
        this.isLoading = true;
        this.groupTestCaseStatList = await groupTestCases(
          groupGuid,
          getStartDateFromDateRange(this.pickedDateRange),
          getEndDateFromDateRange(this.pickedDateRange)
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.clickPresetDateRangeItem(this.defaultPresetDateRange.value);
    this.reloadGroupTestData(this.guid);
  }
};
</script>

<style lang="scss" scoped>
.stat-chart-div {
  overflow: auto;
  max-width: 650px;
}
</style>
