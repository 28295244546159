<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="ml-4">
      <v-card-title>关联被试单位</v-card-title>
      <v-row class="mx-4">
        <v-col cols="10">
          <v-autocomplete
            filled
            dense
            clearable
            :items="pickableDeptList"
            item-text="text"
            item-value="value"
            v-model="selectedDeptGuid"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2">
          <v-btn
            color="primary"
            depressed
            @click="addOneDeptToGroup(selectedDeptGuid)"
            min-width="90"
            >关联</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
    <v-card flat class="ml-4 mb-10">
      <v-card-title>已关联的被试单位</v-card-title>
      <v-row class="mx-4 mb-6">
        <v-col cols="12">
          <v-list>
            <v-list-item v-for="dept in pickedDeptList" :key="dept.value">
              <v-sheet
                class="my-2 px-4 d-flex justify-space-between"
                width="100%"
                elevation="2"
                tile
              >
                <v-list-item-title>{{ dept.text }}</v-list-item-title>
                <v-list-item-action>
                  <v-btn
                    icon
                    color="red"
                    @click="removeOneDeptFromGroup(dept.value)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-sheet>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapGetters } from "vuex";
import {
  fetchPickableDeptList,
  fetchPickedDeptList,
  addDeptToGroup,
  removeDeptFromGroup
} from "@/api/dept.js";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  props: {
    groupGuid: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pickableDeptList: [],
      pickedDeptList: [],
      selectedDeptGuid: "",
      isLoading: false,
      errorMsg: ""
    };
  },

  watch: {
    groupGuid(newGuid) {
      this.selectedDeptGuid = "";
      return this.refreshDeptList(newGuid);
    }
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid"
    })
  },

  methods: {
    async refreshDeptList(groupGuid) {
      try {
        this.isLoading = true;
        this.pickableDeptList = await fetchPickableDeptList(
          this.userGuid,
          groupGuid
        );
        this.pickedDeptList = await fetchPickedDeptList(groupGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async addOneDeptToGroup(deptGuid) {
      try {
        this.isLoading = true;
        await addDeptToGroup(this.groupGuid, deptGuid);
        // 重置控件
        this.selectedDeptGuid = "";
        await this.refreshDeptList(this.groupGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async removeOneDeptFromGroup(deptGuid) {
      try {
        this.isLoading = true;
        await removeDeptFromGroup(this.groupGuid, deptGuid);
        // 重置控件
        await this.refreshDeptList(this.groupGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.refreshDeptList(this.groupGuid);
  }
};
</script>
